// Generated by Framer (4f5e97b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import AcademyCommunityCard from "./lzwDVcT1E";
const AcademyCommunityCardFonts = getFonts(AcademyCommunityCard);

const cycleOrder = ["Aike4iIhD", "PpCVgc0Ek"];

const serializationHash = "framer-ZcKZp"

const variantClassNames = {Aike4iIhD: "framer-v-v40woq", PpCVgc0Ek: "framer-v-1x6uqpn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "Aike4iIhD", "Variant 2": "PpCVgc0Ek"}

const getProps = ({height, id, image, link, width, ...props}) => { return {...props, HGwa8HQQZ: image ?? props.HGwa8HQQZ ?? {src: "https://framerusercontent.com/images/BxaWjC3qHG92Joc8ISa0QF77bsg.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/BxaWjC3qHG92Joc8ISa0QF77bsg.png?scale-down-to=512 512w, https://framerusercontent.com/images/BxaWjC3qHG92Joc8ISa0QF77bsg.png?scale-down-to=1024 1024w, https://framerusercontent.com/images/BxaWjC3qHG92Joc8ISa0QF77bsg.png 1920w"}, qxL2e3s0g: link ?? props.qxL2e3s0g, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Aike4iIhD"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, HGwa8HQQZ, qxL2e3s0g, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Aike4iIhD", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-ZcKZp", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-v40woq", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Aike4iIhD"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(0, 0, 0, 0)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", ...style}} {...addPropertyOverrides({PpCVgc0Ek: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1v8tlmr-container"} layoutDependency={layoutDependency} layoutId={"yl48n6dIC-container"}><AcademyCommunityCard d_ZkIwVA9={"Framer CORE"} gEX32oLZu={qxL2e3s0g} height={"100%"} id={"yl48n6dIC"} layoutId={"yl48n6dIC"} OHKiWD4wE={toResponsiveImage(HGwa8HQQZ)} oZxVSVz0j={"Iniciante"} PRcM0hcU4={"Lenderson Macedo"} RXqtWS9Ks={"3hr 18min"} style={{height: "100%", width: "100%"}} TZtdMDIvP={"Curso PRO"} variant={"TaFwffp25"} vepxiLcSP={"10"} width={"100%"} {...addPropertyOverrides({PpCVgc0Ek: {variant: "LCfEst4C4"}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-ZcKZp [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ZcKZp .framer-7wrvx7 { display: block; }", ".framer-ZcKZp .framer-v40woq { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 292px; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 500px; }", ".framer-ZcKZp .framer-1v8tlmr-container { flex: 1 0 0px; height: 1px; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ZcKZp .framer-v40woq { gap: 0px; } .framer-ZcKZp .framer-v40woq > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-ZcKZp .framer-v40woq > :first-child { margin-top: 0px; } .framer-ZcKZp .framer-v40woq > :last-child { margin-bottom: 0px; } }", ".framer-ZcKZp.framer-v-1x6uqpn .framer-v40woq { width: 355px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 292
 * @framerIntrinsicWidth 500
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"PpCVgc0Ek":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"HGwa8HQQZ":"image","qxL2e3s0g":"link"}
 * @framerImmutableVariables true
 */
const Framerjd5f3XENz: React.ComponentType<Props> = withCSS(Component, css, "framer-ZcKZp") as typeof Component;
export default Framerjd5f3XENz;

Framerjd5f3XENz.displayName = "CourseCard";

Framerjd5f3XENz.defaultProps = {height: 292, width: 500};

addPropertyControls(Framerjd5f3XENz, {variant: {options: ["Aike4iIhD", "PpCVgc0Ek"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, HGwa8HQQZ: {__defaultAssetReference: "data:framer/asset-reference,BxaWjC3qHG92Joc8ISa0QF77bsg.png?originalFilename=Framer+CORE.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, qxL2e3s0g: {title: "Link", type: ControlType.Link}} as any)

addFonts(Framerjd5f3XENz, [...AcademyCommunityCardFonts])